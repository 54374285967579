import "./App.css";
import CountUs from "./countus/countus";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import orange from "@mui/material/colors/orange";

const theme = createTheme({
  palette: {
    primary: orange,
  },
});

function App() {
  // fiogure out max X and Y
  const { innerWidth, innerHeight } = window;

  // force the canvas to take up the entire screen then get its width and height and use that as the max bounds
  // var canvas = document.getElementById('mycanvas');
  // var width = canvas.width;
  // var height = canvas.height;

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CountUs maxWidth={innerWidth} maxHeight={innerHeight} />
      </ThemeProvider>
    </div>
  );
}

export default App;
