import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Chip,
  Grid,
} from "@mui/material";

const GuessBox = ({ proceed }) => {
  const [guess, setGuess] = useState("");
  const [timeLeft, setTimeLeft] = useState(5);

  const sendGuess = useCallback(() => {
    proceed({ guess });
  }, [guess, proceed]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
      sendGuess();
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft, sendGuess]);

  return (
    <Dialog open fullWidth maxWidth="xs">
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <DialogTitle>How many?</DialogTitle>
        </Grid>
        <Grid container justifyContent="flex-end" item xs={12} sm={6}>
          <Chip
            label={`${timeLeft} seconds left`}
            style={{ marginRight: 24 }}
          />
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Input
              fullWidth
              onChange={e => setGuess(e.target.value)}
              value={guess}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="warning" variant="contained" onClick={sendGuess}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GuessBox;
