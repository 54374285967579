import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";

const Explainer = ({ proceed }) => {
  return (
    <Dialog open>
      <DialogTitle>Rules</DialogTitle>
      <DialogContent>
        <div>
          I show you dots for 5 seconds then you have 5 seconds to guess how
          many dots I showed you.
        </div>
      </DialogContent>
      <DialogActions>
        <Button color="warning" variant="contained" onClick={proceed}>
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Explainer;
