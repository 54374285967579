import React, { useState } from "react";
// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import _ from "lodash";
// import ShowItems from "./show-items/show-items";
import Explainer from "./explainer/explainer";
import GuessBox from "./guess/guess";
import ResultsBox from "./results/results";
// import { purple } from "@mui/material/colors";

// const BootstrapButton = styled(Button)({
//   boxShadow: "none",
//   textTransform: "none",
//   fontSize: 16,
//   padding: "6px 12px",
//   border: "1px solid",
//   lineHeight: 1.5,
//   backgroundColor: "#0063cc",
//   borderColor: "#0063cc",
//   fontFamily: [
//     "-apple-system",
//     "BlinkMacSystemFont",
//     '"Segoe UI"',
//     "Roboto",
//     '"Helvetica Neue"',
//     "Arial",
//     "sans-serif",
//     '"Apple Color Emoji"',
//     '"Segoe UI Emoji"',
//     '"Segoe UI Symbol"',
//   ].join(","),
//   "&:hover": {
//     backgroundColor: "#0069d9",
//     borderColor: "#0062cc",
//     boxShadow: "none",
//   },
//   "&:active": {
//     boxShadow: "none",
//     backgroundColor: "#0062cc",
//     borderColor: "#005cbf",
//   },
//   "&:focus": {
//     boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
//   },
// });

// const ColorButton = styled(Button)(({ theme }) => ({
//   color: theme.palette.getContrastText(purple[500]),
//   backgroundColor: purple[500],
//   "&:hover": {
//     backgroundColor: purple[700],
//   },
// }));

const isWithinRange = (num, target, range) => {
  // console.log("num: ", num);
  // console.log("target: ", target);
  // console.log("range: ", range);
  return num >= target - range && num <= target + range;
};
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  if (color.startsWith("#FFF")) {
    return getRandomColor();
  }
  return color;
};

const CountUs = ({ maxWidth, maxHeight }) => {
  const [uiState, setUiState] = useState("explainer");
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const generateRandomPair = (maxWidth, maxHeight, buffer) => {
    const x = getRandomNumber(0 + buffer, maxWidth - buffer);
    const y = getRandomNumber(0 + buffer, maxHeight - buffer);
    return { x, y };
  };

  // make the object a ratio of the available space so not overlapping is always possible
  // 1920x1080
  const smallestMeasure = maxWidth > maxHeight ? maxWidth : maxHeight;
  const ratio = 0.02;
  const getRandomNumToDraw = () =>
    Math.floor(getRandomNumber(0.01 * smallestMeasure, 0.1 * smallestMeasure));
  const [numOfItems, setNumOfItems] = useState(getRandomNumToDraw);
  const [itemWidth] = useState(smallestMeasure * ratio);
  const [itemHeight] = useState(smallestMeasure * ratio);
  const [itemBorderRadius] = useState((smallestMeasure * ratio) / 2);
  const [items, setItems] = useState([]);
  const [guess, setGuess] = useState(null);

  // TODO: set a minimimum canvas size
  // pick random X and Y
  const buildItems = () => {
    const newNumOfItems = getRandomNumToDraw();
    setNumOfItems(newNumOfItems);
    const tempItems = [
      // {x: 160, y: 54},
      // {x: 74, y: 393},
    ];

    const buildAndTestPair = () => {
      const currentPair = generateRandomPair(
        maxWidth - itemWidth,
        maxHeight - itemHeight,
        itemWidth
      );
      const closeToBottom = isWithinRange(currentPair.y, maxHeight, itemHeight);
      const closeToRight = isWithinRange(currentPair.x, maxWidth, itemWidth);

      const tooCloseToSides = closeToBottom || closeToRight;
      if (tooCloseToSides) {
        return buildAndTestPair();
      }

      if (tempItems.length) {
        let somethingOverlapped = false;
        const overlappingPairs = [currentPair];
        _.forEach(tempItems, item => {
          if (somethingOverlapped) {
            return;
          }
          // check that that X and Y are not already being used or within the maximum bounds of the item
          const xWasOverlapping = isWithinRange(
            currentPair.x,
            item.x,
            itemWidth
          );
          const yWasOverlapping = isWithinRange(
            currentPair.y,
            item.y,
            itemHeight
          );
          if (xWasOverlapping && yWasOverlapping) {
            somethingOverlapped = true;
            overlappingPairs.push(item);
          }
        });
        if (somethingOverlapped) {
          return buildAndTestPair();
        }
        return currentPair;
      } else {
        return currentPair;
      }
    };

    [...Array(newNumOfItems)].forEach((_, i) => {
      // pick random X and Y within bounds
      const goodPair = buildAndTestPair();
      tempItems.push(goodPair);
    });
    setItems(tempItems);
  };
  const renderAnItem = ({ x, y }) => {
    // write each item as a div to the screen
    // STYLES:
    //  - absolutely positioned using the X and Y
    //  - sized using the itemWidth and itemHeight
    //  - borderRadius set as itemBorderRadius
    return (
      <div
        key={`${x}-${y}`}
        style={{
          position: "absolute",
          left: x,
          top: y,
          width: itemWidth,
          height: itemHeight,
          borderRadius: itemBorderRadius,
          backgroundColor: getRandomColor(),
        }}
      />
    );
  };

  // for now just use a basic CSS shape (circle, square, etc) and a random color
  // later we can add images and other things
  const proceedToGame = () => {
    setGuess(null);
    buildItems();
    setUiState("game");
    setTimeout(() => {
      setUiState("guess");
    }, 5000);
  };

  const handleGuess = ({ guess }) => {
    setGuess(guess);
    setUiState("results");
  };

  const handleReset = () => {
    proceedToGame();
  };

  return (
    <Stack spacing={2} direction="row">
      {uiState === "explainer" && <Explainer proceed={proceedToGame} />}
      {uiState === "game" && items.length > 0 && items.map(renderAnItem)}
      {uiState === "guess" && <GuessBox proceed={handleGuess} />}
      {uiState === "results" && (
        <ResultsBox guess={guess} actual={numOfItems} reset={handleReset} />
      )}
    </Stack>
  );
};

export default CountUs;
