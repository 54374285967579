import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";

const ResultsBox = ({ guess, actual, reset }) => {
  const absResult = Math.abs(guess - actual);
  const [resultComment] = useState(() => {
    let thingToSay = "";
    if (absResult === 0) {
      thingToSay = "Well look at you, smarty pants.";
    } else if (absResult < 10) {
      thingToSay = "Ouuuuuu, so close! But close only counts in horseshoes.";
    } else if (absResult < 20) {
      thingToSay = "Not bad, not bad. But you can do better.";
    } else if (absResult >= 20) {
      thingToSay = "Did you blink for theewntire 5 seconds or something?";
    }
    return thingToSay;
  });
  const [forClipboard] = useState(() => {
    return `My Snappy👌Count🔢 score was ${absResult}! Think you can beat me?\n\nTry it at https://snappycount.com`;
  });

  return (
    <Dialog open>
      <DialogContent>
        <Typography
          variant="h3"
          align="center"
          style={{ color: "#ed6c02" }}
          gutterBottom
        >
          {absResult === 0 ? "Right on!" : <>{absResult} off!</>}
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          There were {actual} of them.
        </Typography>
        <Typography variant="body2" align="center">
          {resultComment}
        </Typography>
      </DialogContent>
      <DialogActions>
        <CopyToClipboard text={forClipboard}>
          <Button color="warning" variant="outlined">
            Copy Result To Brag To Your Friends
          </Button>
        </CopyToClipboard>
        <Button color="warning" variant="contained" onClick={reset}>
          Try Again
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ResultsBox;
